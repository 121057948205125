import React from 'react'
import styled from 'styled-components'
import {GatsbyImage} from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import {Link} from 'gatsby'

const Holder = styled.div`
  margin-bottom: 5rem;
  @media ( ${props => props.theme.breakpoints.md} ) {
    margin-bottom: 8rem;
  }

  h1 {
    transition: color 300ms ease-in-out;
  }

  > a {
    display: block;
    text-decoration: none;
    @media ( ${props => props.theme.breakpoints.md} ) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 2rem;
    }
    @media ( ${props => props.theme.breakpoints.lg} ) {
      display: inherit;
    }

    .gatsby-image-wrapper {
      height: 300px;
      object-fit: cover;
      min-height: 100%;
      min-width: 100%;
      @media ( ${props => props.theme.breakpoints.md} ) {
        height: 250px;
      }
      @media ( ${props => props.theme.breakpoints.lg} ) {
        height: 300px;
      }
    }

    &:hover h1 {
      text-decoration: underline;
      text-decoration-thickness: 2px;
      color: ${props => props.theme.colours.blue};
    }
  }
`

ProjectCard.propTypes = {
    image: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    lede: PropTypes.object.isRequired,
    key: PropTypes.number.isRequired,
    link: PropTypes.string.isRequired,
}

function ProjectCard({image, title, lede, key, link}) {
    return (
        <Holder key={key}>
            <Link to={link}>
                <GatsbyImage alt={image.title} image={image.gatsbyImageData}/>
                <div>
                    <h1>{title}</h1>
                    <p style={{lineHeight: '1.36'}}>{lede}</p>
                </div>
            </Link>
        </Holder>
    )
}

export default ProjectCard