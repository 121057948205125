import React from 'react'
import Container from '../components/atoms/Container'
import {graphql} from 'gatsby'
import styled from 'styled-components'
import ProjectListCard from '../components/organisms/ProjectListCard'

const CardHolder = styled.div`
  border-top: 2px solid ${props => props.theme.colours.blue};

  > h3 {
    color: ${props => props.theme.colours.blue};
    margin-bottom: 3rem;
  }
`

const ProjectGrid = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 4rem;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: unset;
  }

  // Only constrain project cards on project list page 
  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
    max-height: 300px;
    object-fit: cover;
    overflow: hidden;
  }

  > div {
    margin-bottom: 0 !important;

    h1 {
      ${(props) => props.theme.fluidType(4)};
    }

    > :last-child {
      width: initial !important;
    }
  }
`

function ProjectListPage({data}) {
    const {nodes} = data.allContentfulProject

    return (
        <Container>
            <CardHolder>
                <h3>Projects</h3>
                <ProjectGrid>
                    {nodes && nodes.map((node, index) => {
                        return <ProjectListCard image={node.excerptImage} lede={node.lede}
                                                link={'/projects/' + node.slug}
                                                title={node.title}
                                                key={index}/>
                    })}
                </ProjectGrid>
            </CardHolder>
        </Container>
    )
}

export const pageQuery = graphql`
    query projectListPageQuery {
        allContentfulProject(sort: {fields: date, order: DESC}) {
            nodes {
                title
                id
                slug
                excerptImage {
                  gatsbyImageData
                  title
                  description
                }
                lede
            }
        }
    }
`

export default ProjectListPage